<template>
  <div>
    <mail-template-create :mail-template-update="mailTemplate" :title="$t('mails.update_mail')"></mail-template-create>
  </div>
</template>
<script>
    import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
    import MailTemplateCreate from "@/views/mail-templates/MailTemplateCreate";
    import {MailTemplateService} from "@/services";

    export default {
        name: "MailTemplateUpdate",
        components: {MailTemplateCreate},
        data() {
            return {
                mailTemplate: {},
            }
        },
        computed: {
            mailTemplateId() {
                return this.$route.params.id
            }
        },
        methods: {
            async getUserService() {
                if (this.mailTemplateId) {
                    await MailTemplateService.detail(this.mailTemplateId).then((res) => {
                        if(res && res.data) {
                            this.mailTemplate = res.data
                        }
                    }).catch((err) => {
                        this.$toast.error(err.data.message)
                    })
                }
            },
        },
        created() {
            this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'mail-templates')
            this.getUserService()
        }
    }
</script>
<style scoped>

</style>